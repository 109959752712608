import {
  ImagePlaceholder,
  Modal,
  properties,
  Subtitle,
  Title,
  IProduct,
} from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import AddReviewForm from '../AddReviewForm'

const { ResponsiveBreakpoints } = properties

type AddReviewModalProps = {
  onClose: any
  isOpen: boolean
  product: IProduct
}

const defaultProps = {}

const ProductImage = styled.img`
  height: auto;
  margin: 10px;
`

const RightPanel = styled.div`
  margin: 10px;
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  margin: -10px;

  > ${ProductImage}, > ${ImagePlaceholder} {
    flex: 1 1 auto;
    max-width: 250px;
  }

  > ${RightPanel} {
    flex: 0 0 350px;
  }

  @media screen and (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;
    align-items: center;

    > ${ProductImage}, > ${ImagePlaceholder} {
      flex: 0 1 auto;
    }

    > ${RightPanel} {
      flex: 0 1 auto;
      width: 100%;
    }
  }
`

const ModalSubtitle = styled(Subtitle)`
  font-size: 1.1rem;
  margin-bottom: 3em;
`

const AddReviewModal = function AddReviewModal({
  onClose,
  isOpen,
  product: { code, name, featuredImage },
  ...props
}: AddReviewModalProps) {
  return (
    <Modal size="large" handleCloseModal={onClose} open={isOpen} {...props}>
      <Container>
        {featuredImage ? (
          <ProductImage src={featuredImage} alt={`${code}, ${name}`} />
        ) : (
          <ImagePlaceholder />
        )}
        <RightPanel>
          <Title size="small">Leave a review</Title>
          <ModalSubtitle textAlign="left">
            {code}, {name}
          </ModalSubtitle>
          <AddReviewForm productCode={code} onCompleted={onClose} />
        </RightPanel>
      </Container>
    </Modal>
  )
}

AddReviewModal.defaultProps = defaultProps

export default AddReviewModal
