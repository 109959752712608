import * as React from 'react'
import styled, { css } from 'styled-components'
import { Budicon, BudiconSize } from '@awdis/components'

type RatingCProps = {
  value: number | string
  name?: string
  onChange?: (evt: React.MouseEvent<HTMLButtonElement>) => void
  max?: number
  as?: React.ReactType
  className?: string
  size?: BudiconSize
  disabled?: boolean
}

const defaultProps = {
  as: 'div',
  max: 5,
  size: 'medium',
}

const StarItem = styled.span<{
  as?: string
  disabled?: boolean
  name?: string
  value?: number
}>`
  border: none;
  background: none;
  padding: 0;

  &:not(:disabled) {
    ${({ as }) =>
      as === 'button'
        ? css`
            cursor: pointer;
          `
        : ''};

    // Icon
    &:hover > span {
      font-weight: bold;
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }
  }
`

const RatingC = function RatingC({
  as: As,
  className,
  max,
  name,
  value,
  size,
  onChange,
  disabled,
  ...props
}: RatingCProps) {
  const parsedValue =
    typeof value === 'number' ? value : parseInt(value, 10) || 0
  return (
    <As
      className={className}
      aria-label={`Rating of ${parsedValue} stars`}
      {...props}
    >
      {/* Create an empty array of max elements */}
      {Array(max)
        .fill('')
        .map((_, index) => (
          <StarItem
            key={index}
            as={onChange ? 'button' : undefined}
            onClick={onChange}
            name={name}
            value={onChange && index + 1}
            disabled={disabled}
          >
            <Budicon size={size} solid={index + 1 <= parsedValue} name="star" />
          </StarItem>
        ))}
    </As>
  )
}

RatingC.defaultProps = defaultProps

const Rating = styled(RatingC)`
  align-items: center;
  display: flex;
  margin: -4px;
  margin-bottom: 5px;

  > ${StarItem} {
    flex: 0 0 auto;
    margin: 4px;
  }
`

export default Rating
