import { Button, SingleProductPage, IProduct } from '@awdis/components'
import { graphql } from 'gatsby'
import * as React from 'react'
import Layout, { LayoutData } from '../../components/Layout'
import RouterLinkButton from '../../components/RouterLinkButton'
import SemanticRouterLink from '../../components/SemanticRouterLink'
import Seo from '../../components/Seo'
import NotFound from '../NotFound'
import AddReviewModal from './components/AddReviewModal'
import ProductReviewList from './components/ProductReviewList'

type ProductDetailsProps = {
  data?: {
    awdis?: {
      product?: IProduct
    }
  }
  pageContext: {
    productId: string
    layoutData: LayoutData
  }
}

const defaultProps = {}

const ProductDetails = function ProductDetails({
  data: { awdis: { product = null } = {} } = {},
  pageContext: { layoutData },
}: ProductDetailsProps) {
  const [isAddReviewModalOpen, setIsAddReviewModalOpen] = React.useState(false)

  if (!product) return <NotFound pageContext={{ layoutData }} />

  return (
    <Layout data={layoutData}>
      <Seo
        type="product"
        title={product.code}
        description={product.description}
        image={product.featuredImage}
        imageParameters={{
          crop: 'faces',
        }}
        pathname={`products/${product.code}`}
      />

      <SingleProductPage
        product={product}
        routerLinkComp={SemanticRouterLink}
        routerLinkButtonComp={RouterLinkButton}
        hrefPropName="to"
        makeCardLinkProps={({ code }) => ({
          to: `/products/${code}`,
          fluid: true,
        })}
        extraInfoAccordions={
          [
            // {
            //   title: 'Reviews',
            //   children: (
            //     <>
            //       <Button
            //         onClick={() => setIsAddReviewModalOpen(true)}
            //         style={{ marginBottom: '1.75em' }}
            //       >
            //         Leave a review
            //       </Button>
            //       <ProductReviewList productCode={product.code} paginated />
            //     </>
            //   ),
            // },
          ]
        }
      />

      <AddReviewModal
        isOpen={isAddReviewModalOpen}
        onClose={() => setIsAddReviewModalOpen(false)}
        product={product}
      />
    </Layout>
  )
}

export const query = graphql`
  query GetProductDetails($productId: ID!) {
    awdis {
      product(where: { id: $productId }) {
        id
        name
        brand {
          id
          name
        }
        featuredImage
        hoverImage
        description
        code
        gallery
        sizeGuide

        colours {
          id
          colour {
            id
            type
            name
            hex
            swatch
            categories {
              id
              name
            }
          }
          sizes {
            id
            size
          }
          torsoImage
        }

        category {
          id
          name
        }

        certifications {
          id
          name
          url
          description
        }

        tags {
          id
          name
        }

        packSize {
          id
          name
        }

        fabricContent {
          id
          name
        }

        fabricGauge {
          id
          name
        }

        fabricWeight {
          id
          name
        }

        relatedProducts {
          id
          name
          featuredImage
          hoverImage
          description
          code
          tags {
            id
            name
          }

          colours {
            id
            colour {
              id
              type
              name
              hex
              swatch
              categories {
                id
                name
              }
            }

            sizes {
              id
              size
            }
          }

          category {
            id
            name
          }
        }
      }
    }
  }
`

ProductDetails.defaultProps = defaultProps

export default ProductDetails
