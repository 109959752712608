import * as React from 'react'
import Form, { FormInput } from '../Form'
import LabeledInput from '../LabeledInput'
import Rating from '../Rating'
import styled from 'styled-components'
import TextArea from '../TextArea'

export type ReviewFormProps = {
  onSubmit: (form: {
    rating: number
    title: string
    text: string
    author: string
  }) => void
  isLoading?: boolean
  message?: {
    type: 'error' | 'success'
    content: string
  }
}

export type ReviewFormState = {
  form: {
    rating: string
  }
}

const RatingInput = styled(LabeledInput)`
  font-size: 1.1rem;
  margin-bottom: 1.2em;

  > label {
    font-weight: 500;
  }

  > ${Rating} {
    display: inline-block;
  }
`

const REVIEW_FORM_FIELDS: Array<FormInput> = [
  {
    name: 'title',
    label: 'Review title',
    placeholder: 'Your title',
    required: true,
  },
  {
    name: 'text',
    label: 'Review',
    placeholder: 'Your review',
    control: TextArea,
    required: true,
  },
  {
    name: 'author',
    label: 'Display name',
    placeholder: 'Your name',
    required: true,
  },
]

class ReviewForm extends React.Component<ReviewFormProps, ReviewFormState> {
  state: ReviewFormState = {
    form: {
      rating: '0',
    },
  }

  handleChange = (target, { currentTarget: { name, value } } = target) => {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value,
      },
    }))
  }

  handleSubmit = (form: { title: string; text: string; author: string }) => {
    const { onSubmit } = this.props
    const {
      form: { rating },
    } = this.state

    const parsedRating = parseInt(rating, 10)
    if (isNaN(parsedRating)) {
      console.error(`Could not parse rating ${rating}`)
      return
    }

    const data = { ...form, rating: parsedRating }
    onSubmit(data)
  }

  render() {
    const { isLoading, onSubmit, ...props } = this.props
    const {
      form: { rating },
    } = this.state
    return (
      <>
        <RatingInput
          id="review-form-rating"
          label="Overall Rating"
          control={Rating}
          name="rating"
          onChange={this.handleChange}
          value={rating}
          size="small"
          disabled={isLoading}
          inline
        />
        <Form
          id="review-form"
          fields={REVIEW_FORM_FIELDS}
          onSubmit={this.handleSubmit}
          isLoading={isLoading}
          initialState={{
            title: '',
            author: '',
            text: '',
          }}
          {...props}
        />
      </>
    )
  }
}

export default ReviewForm
