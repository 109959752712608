import gql from 'graphql-tag'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import GetReCaptchaToken from '../../../../components/GetReCaptchaToken'
import ReviewForm from '../../../../components/ReviewForm'

export type AddReviewFormProps = {
  productCode: string
  onCompleted?: any
}

const ADD_PRODUCT_REVIEW_MUTATION = gql`
  mutation ADD_PRODUCT_REVIEW_MUTATION(
    $review: ProductReviewInput!
    $productCode: String!
    $recaptchaToken: String!
  ) {
    addProductReview(
      data: $review
      product: { code: $productCode }
      token: $recaptchaToken
    ) {
      id
    }
  }
`

const AddReviewForm = function AddReviewForm({
  productCode,
  ...props
}: AddReviewFormProps) {
  return (
    <Mutation mutation={ADD_PRODUCT_REVIEW_MUTATION} {...props}>
      {(addProductReview, { loading, error }) => (
        <GetReCaptchaToken action="submitReview">
          {({ requestRecaptchaToken, isLoading: isLoadingRecaptcha }) => (
            <ReviewForm
              onSubmit={async review => {
                const recaptchaToken = await requestRecaptchaToken()

                const variables = {
                  review,
                  productCode,
                  recaptchaToken,
                }

                await addProductReview({ variables })
              }}
              isLoading={loading || isLoadingRecaptcha}
              message={
                error
                  ? {
                      type: 'error',
                      content: error.message,
                    }
                  : undefined
              }
            />
          )}
        </GetReCaptchaToken>
      )}
    </Mutation>
  )
}

export default AddReviewForm
